import styled from 'styled-components';
import GetContract from '../../types/responses/api/v1/order/GetContract';

import Header from './Header';
import SetTable from './SetTable';

export type ContractPrintProps = GetContract & {
  totalPage: number;
  currentPage: number;
};

function ContractPrint(props: ContractPrintProps): JSX.Element {
  return (
    <Wrapper>
      <Header {...props} />
      <SetTable products={props.products} />
      <PageNumber>
        Page {props.currentPage} of {props.totalPage}
      </PageNumber>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  font-size: 10pt;
  padding: 7mm;
  background-color: white;

  @media only screen {
    width: 297mm;
    height: 210mm;
    box-shadow: 4px 4px 5px 0px #9e9e9e;
    margin-bottom: 20px;
  }
  @media only print {
    page-break-before: always;
  }
`;

const PageNumber = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media only screen {
    bottom: 5mm;
  }
  @media only print {
    bottom: 0;
  }
`;

export default ContractPrint;
