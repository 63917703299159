import styled from 'styled-components';

interface ApprovalColumnProps {
  style?: React.CSSProperties;
}

function ApprovalColumn(props: ApprovalColumnProps): JSX.Element {
  return (
    <Table style={props.style}>
      <thead>
        <tr>
          <Th></Th>
          <Th>담당</Th>
          <Th>관리1</Th>
          <Th>관리2</Th>
          <Th>임원</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Th style={{ padding: '0 1mm' }}>결재란</Th>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </tr>
      </tbody>
    </Table>
  );
}

const Table = styled.table``;

const Th = styled.th`
  height: 5mm;
  border: 0.2mm solid black;
  vertical-align: middle;
`;

const Td = styled.td`
  border: 0.2mm solid black;
  width: 15mm;
  height: 10mm;
  text-align: center;
`;

export const ApprovalColumnWrapperStyle = Table;
export default ApprovalColumn;
