import styled from 'styled-components';

const SetTableHead: React.FC = (): JSX.Element => {
  return (
    <thead>
      <tr>
        <ThRow2 rowSpan={2} style={{ width: '50mm' }}>
          세트 / 품명 / 비고
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '20mm' }}>
          위치
          <br />
          색상
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '8mm' }}>
          좌우
        </ThRow2>
        <Th colSpan={4}>수량</Th>
        <ThRow2 rowSpan={2} colSpan={2} style={{ width: '23mm' }}>
          규격(WxH)
        </ThRow2>
        <ThRow2 rowSpan={2}></ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '16mm' }}>
          고정틀
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '16mm' }}>
          프레임
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '10mm' }}>
          수량
        </ThRow2>
        <ThRow2 rowSpan={2} colSpan={2}>
          스크린
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '12mm' }}>
          티바
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '10mm' }}>
          수량
        </ThRow2>
        <ThRow2 rowSpan={2}>
          CI길이/
          <br />
          수량
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '11mm' }}>
          깊이
          <br />
          W/H
        </ThRow2>
        <ThRow2 rowSpan={2} style={{ width: '11mm' }}>
          레일
          <br />폭
        </ThRow2>
      </tr>
      <tr>
        <Th style={{ width: '10mm' }}>계</Th>
        <Th style={{ width: '10mm' }}>좌</Th>
        <Th style={{ width: '10mm' }}>우</Th>
        <Th style={{ width: '10mm' }}>기타</Th>
      </tr>
    </thead>
  );
};

const Th = styled.th`
  border: 0.2mm solid black;
  vertical-align: middle;
`;

const ThRow2 = styled(Th)`
  height: 9mm;
`;

export default SetTableHead;
