import styled, { createGlobalStyle } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';

import ContractPrint from '../components/contract-print';
import GetContractResponse from '../types/responses/api/v1/order/GetContract';
import { useMemo } from 'react';
import { ContractPrintProps } from '../components/contract-print/ContractPrint';

const ONE_PAGE_COUNT = 11;

function PrintPage(): JSX.Element {
  const { contractNo } = useParams();

  const { data, isLoading, isError } = useQuery(`contract/${contractNo}`, async () => {
    const res = await axios.get<GetContractResponse>(`${process.env.REACT_APP_API}/order/contracts/${contractNo}`);
    return res.data;
  });

  const printInfos = useMemo(() => {
    const infos: ContractPrintProps[] = [];
    if (data) {
      const totalPage = Math.ceil(data!.products.length / ONE_PAGE_COUNT);
      for (let i = 0; i < data!.products.length; i += ONE_PAGE_COUNT) {
        const info = { ...data! } as ContractPrintProps;
        info.products = data!.products.slice(i, i + ONE_PAGE_COUNT);
        info.currentPage = Math.floor(i / ONE_PAGE_COUNT) + 1;
        info.totalPage = totalPage;
        infos.push(info);
      }
    }
    return infos;
  }, [data]);

  if (isLoading) {
    return <div>로딩중</div>;
  }

  if (isError) {
    return <div>에러!</div>;
  }

  return (
    <Wrapper>
      <PrintPageStyle />
      <PageArea>
        {printInfos.map((info) => (
          <ContractPrint key={info.currentPage} {...info} currentPage={info.currentPage} totalPage={info.totalPage} />
        ))}
      </PageArea>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media only screen {
    width: 100%;
    height: 100%;
    background-color: lightgrey;
  }
`;

const PrintPageStyle = createGlobalStyle`
  @page {
    size: A4 landscape;
    margin: 0mm;
  }
`;

const PageArea = styled.div`
  @media only screen {
    background-color: lightgrey;
  }
`;

export default PrintPage;
