import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import GetContractResponse from '../../types/responses/api/v1/order/GetContract';

import ApprovalColumn from './ApprovalColumn';
import ContractTable from './ContractTable';

type HeaderProps = Omit<GetContractResponse, 'products'>;

function Header(props: HeaderProps): JSX.Element {
  const { contractNo } = useParams();
  const today = useMemo(() => dayjs().format('YYYY-MM-DD'), []);

  return (
    <Wrapper>
      <Title>작&nbsp;업&nbsp;지&nbsp;시&nbsp;서 생산용</Title>
      <ApprovalColumn style={{ position: 'absolute', top: 0, right: 0 }} />
      <ContractNoAndPrintDate>
        <p>주문번호: {contractNo}</p>
        <p>출력일자: {today}</p>
      </ContractNoAndPrintDate>
      <ContractTable {...props} />
    </Wrapper>
  );
}

const Wrapper = styled.header`
  position: relative;
`;

const Title = styled.h1`
  margin-bottom: 9mm;
  text-decoration: underline;
  text-align: center;
  font-size: 7mm;
  letter-spacing: 1mm;
`;

const ContractNoAndPrintDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1mm;
`;

export default Header;
