import styled from 'styled-components';

import SetTableHead from './SetTableHead';
import SetRow from './SetRow';
import GetContract from '../../types/responses/api/v1/order/GetContract';

type SetTableProps = Pick<GetContract, 'products'>;

function SetTable(props: SetTableProps): JSX.Element {
  return (
    <Table>
      <SetTableHead />
      <tbody>
        {props.products.map((product) => (
          <SetRow key={product.setNo} product={product} />
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
`;

export default SetTable;
