import { useMemo } from 'react';
import styled from 'styled-components';
import GetContract from '../../types/responses/api/v1/order/GetContract';

type SetRowProps = {
  product: GetContract['products'][number];
};

const DIRECTION: Record<GetContract['products'][number]['direction'], string> = {
  LEFT: '좌',
  RIGHT: '우',
  BOTTOM: '하',
  NONE: '없음',
};

const SCREEN: Record<GetContract['products'][number]['screenType'], string> = {
  T04: '망0.4(16)',
  T04MESH20: '망0.4(20)',
  T05: '망 0.5',
  T07: '망 0.7',
  T08: '망 0.8',
};

function SetRow({ product }: SetRowProps): JSX.Element {
  const totalCount = product.count;
  let leftCount: number | null = null;
  let rightCount: number | null = null;
  let etcCount: number | null = null;

  const cuttingCount = totalCount * 2;

  switch (product.direction) {
    case 'LEFT':
      leftCount = totalCount;
      break;
    case 'RIGHT':
      rightCount = totalCount;
      break;
    default:
      etcCount = totalCount;
      break;
  }

  const productNameToSale = product.productName;
  const productNameToMake = useMemo(() => {
    const code = product.productCode;
    let name = '';
    if (code.startsWith('WIN-CMI-IN')) {
      name = '케이스먼트IN 내부';
    } else if (code.startsWith('WIN-CMI-OUT')) {
      name = '케이스먼트IN 외부';
    } else if (code.startsWith('WIN-CMO')) {
      name = '케이스먼트OUT';
    } else if (code.startsWith('WIN-STD')) {
      name = '일반창';
    } else if (code.startsWith('WIN-FIX')) {
      name = '고정창';
    } else if (code.startsWith('WIN-W4')) {
      name = '네짝문';
      name += code.includes('CI') ? ' CI포함' : ' CI미포함';
    }

    name += '-' + code.match(/0\.((4|5|7|8)(\(.+\))?)/)![0];

    const sash = code.match(/S(56|60|72A|72GS20|72GS30)/);
    if (sash) {
      name += '-' + sash[1];
    }

    return name;
  }, [product.productCode]);

  let bottomComment: string = '';
  if (product.direction === 'BOTTOM') {
    if (product.productCode.startsWith('WIN-CM')) {
      bottomComment = '(손잡이는 하입니다.)';
    } else {
      bottomComment = '(방향(하)는 케이스먼트만 허용됩니다.)';
    }
  }

  return (
    <>
      <tr>
        <TdProductName colSpan={20}>
          {productNameToSale} {bottomComment}
        </TdProductName>
      </tr>
      <tr>
        <TdNoBottomBorder>
          <NoWrapText>
            {product.setNo} <ProductCode>{productNameToMake}</ProductCode>
          </NoWrapText>
        </TdNoBottomBorder>
        <TdLocationColor rowSpan={2}>
          <Bold>
            {product.location} {product.frameColor}
          </Bold>
        </TdLocationColor>
        <Td2Row rowSpan={2} style={{ textAlign: 'center' }}>
          {DIRECTION[product.direction]}
        </Td2Row>
        <Td style={{ textAlign: 'right' }}>{totalCount.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{leftCount?.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{rightCount?.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{etcCount?.toLocaleString()}</Td>
        <TdNoBottomBorder style={{ borderRight: 'none', textAlign: 'right' }}>{product.width.toLocaleString()}</TdNoBottomBorder>
        <TdNoBottomBorder style={{ borderLeft: 'none' }}></TdNoBottomBorder>
        <Td style={{ textAlign: 'center' }}>W</Td>
        <Td style={{ textAlign: 'right' }}>
          <Bold>{product.fixedWidth?.toLocaleString()}</Bold>
        </Td>
        <Td style={{ textAlign: 'right' }}>
          <Bold>{product.frameWidth?.toLocaleString()}</Bold>
        </Td>
        <Td style={{ textAlign: 'right' }}>{cuttingCount.toLocaleString()}</Td>
        <Td>{SCREEN[product.screenType]}</Td>
        <Td style={{ textAlign: 'right' }}>{product.screenWidth.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{product.tBarWidth.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{cuttingCount.toLocaleString()}</Td>
        <Td></Td>
        <Td style={{ textAlign: 'right' }}>{product.railHorizontalDepth}</Td>
        <Td2Row rowSpan={2} style={{ textAlign: 'right' }}>
          {product.railWidth}
        </Td2Row>
      </tr>
      <tr>
        <TdNoTopBorder>{product.memo}</TdNoTopBorder>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <TdNoTopBorder style={{ borderRight: 'none' }}></TdNoTopBorder>
        <TdNoTopBorder style={{ borderLeft: 'none', textAlign: 'right' }}>{product.height.toLocaleString()}</TdNoTopBorder>
        <Td style={{ textAlign: 'center' }}>H</Td>
        <Td style={{ textAlign: 'right' }}>
          <Bold>{product.fiexdHeight?.toLocaleString()}</Bold>
        </Td>
        <Td style={{ textAlign: 'right' }}>
          <Bold>{product.frameHeight?.toLocaleString()}</Bold>
        </Td>
        <Td style={{ textAlign: 'right' }}>{cuttingCount.toLocaleString()}</Td>
        <Td>{product.screenColor}</Td>
        <Td style={{ textAlign: 'right' }}>{product.screenHeight.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{product.tBarHeight.toLocaleString()}</Td>
        <Td style={{ textAlign: 'right' }}>{cuttingCount.toLocaleString()}</Td>
        <Td>{product.ciLength?.toLocaleString()}</Td>
        <Td style={{ textAlign: 'left' }}>{product.railVerticalDepth}</Td>
      </tr>
    </>
  );
}

const TdProductName = styled.td`
  border: 0.2mm solid black;
  vertical-align: middle;
  padding: 0 0.5mm;
`;

const Td = styled.td`
  border: 0.2mm solid black;
  vertical-align: middle;
  padding: 0 0.5mm;
`;

const Td2Row = styled(Td)`
  height: 9.5mm;
`;

const TdLocationColor = styled(Td2Row)`
  padding: 0.5mm;
  font-size: 3mm;
  vertical-align: top;
`;

const TdNoBottomBorder = styled(Td)`
  border-bottom: none;
`;

const TdNoTopBorder = styled(Td)`
  border-top: none;
`;

const NoWrapText = styled.p`
  overflow: hidden;
  white-space: nowrap;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ProductCode = styled.span`
  font-weight: bold;
`;

export default SetRow;
