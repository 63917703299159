import styled from 'styled-components';
import GetContractResponse from '../../types/responses/api/v1/order/GetContract';

type ContractTableProps = Omit<GetContractResponse, 'products'>;

function ContractTable(props: ContractTableProps): JSX.Element {
  return (
    <Table>
      <tbody>
        <tr>
          <th>거래처</th>
          <td style={{ width: '36mm' }}>{props.agencyName}</td>
          <th>현장</th>
          <td>{props.location}</td>
          <th style={{ width: '18mm' }}>지시일자</th>
          <td style={{ width: '22mm' }}>{props.productionOrderDate}</td>
          <th style={{ width: '18mm' }}>주문일</th>
          <td style={{ width: '22mm' }}>{props.orderDate}</td>
          <th style={{ width: '18mm' }}></th>
          <td style={{ width: '22mm', textAlign: 'center' }}></td>
        </tr>
        <tr>
          <th>비고</th>
          <td colSpan={5}>{props.memo}</td>
          <th>납기</th>
          <td></td>
          <th></th>
          <td style={{ textAlign: 'justify' }}></td>
        </tr>
        <tr>
          <th>주문정보</th>
          <td colSpan={9}>{props.orderInfo}</td>
        </tr>
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;

  tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }

  th,
  td {
    height: 6mm;
    border: 0.2mm solid black;
    vertical-align: middle;
  }

  th {
    min-width: 14mm;
  }

  td {
    padding: 0 1mm;
  }
`;

export default ContractTable;
